export const sellerEndpoint = '/api/sellers'
export const userEndpoint = '/api/users'
export const driverEndpoint = '/api/users/driver'
export const customerEndpoint = '/api/customers'
export const relayPointEndpoint = '/api/relayPoint'
export const orderEndpoint = '/api/orders'
export const orderTrackingEndpoint = '/api/tracking/order'
export const mainOrderTrackingEndpoint = '/api/tracking/mainOrder'

export const deliveryType = '/api/delivery_type'
export const userRole = '/api/roles'
export const trackingStatus = '/api/status'
export const allStatistics = '/api/statistic'
export const ordersStatistics = '/api/statistic/order'
export const salesStatistics = '/api/statistic/sale'
export const driverByAdminStatistics = '/api/statistic/admin'

export const allNotifications = '/api/notifications'

export const DriverAreas = '/api/driverArea'
export const suggestionsEndpoint = '/api/suggestions'
export const mainOrderEndpoint = '/api/mainOrders'
export const driverArea ='/api/driverArea'
export const assignDriver='/api/orders/assign-order'