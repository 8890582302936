import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {useQueryResponseLoading} from '../core/QueryResponseProvider'
import {driversColumns} from './columns/_columns'
// import { User } from '../core/_models';
import {DriversListLoading} from '../components/loading/DriversListLoading'
// import {UsersListPagination} from '../components/pagination/UsersListPagination'
import {KTCard, KTCardBody} from '../../../../../_template/helpers'
import {useSelector} from 'react-redux'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './CustomRow'
import {DriversListSearchComponent} from '../components/header/DriversListSearchComponent'
import PaginationComponent from '../../../../../_template/layout/components/pagination'
import {getAllFunction} from '../../../../redux/actions/commonCrud'
import {useAppDispatch} from '../../../../hooks'
// import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import * as endpoints from '../../../../../_template/helpers/endpoints'

type Props = {
  eventKey?: string | undefined
  setCount: any
}
type values = {
  role?: string | undefined
  phoneNumber?: string | undefined
  status?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string | undefined
}
const DriversTable: React.FC<Props> = ({eventKey, setCount}) => {
  const REACT_APP_ROLE_DRIVER = process.env.REACT_APP_ROLE_DRIVER ||""

  const users: IUsers[] = useSelector(({users}: {users: UsersState}) => {
    return users.usersList
  })
  const totalPages: number = useSelector(({users}: {users: UsersState}) => {
    return users.totalPages
  })
  const totalItems: number = useSelector(({users}: {users: UsersState}) => {
    return users.totalItems
  })

  const [values, setValues] = useState<values | undefined>({
    status: eventKey === 'All' ? undefined : eventKey,
  })
  const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      role: REACT_APP_ROLE_DRIVER,
      [e.target.name]: e.target.value,
    })
  }
  const isLoading = useQueryResponseLoading()
  const data: IUsers[] = useMemo(() => users, [users])
  const columns = useMemo(() => driversColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const dispatch = useAppDispatch()
  const handleDispatch = () => {
    dispatch(
      getAllFunction(endpoints.userEndpoint, actionTypes.GET_ALL_USERS, {
        ...values,
        role: REACT_APP_ROLE_DRIVER,
      })
    )
  }
  const resetData = () => {
    setValues({firstName: '', lastName: '', role: '', phoneNumber: ''})
  }

  useEffect(() => {
    if (totalItems) {
      setCount(totalItems)
    } else {
      setCount(0)
    }
  }, [totalItems])
  return (
    <KTCard>
      <KTCardBody className='py-4'>
        {/* <div className='d-flex justify-content-between'> */}
        <div className='w-md-100'>
          <DriversListSearchComponent
            resetData={resetData}
            values={values}
            handleChangeValues={handleChangeValues}
            handleDispatch={handleDispatch}
            eventKey={eventKey}
          />
        </div>
        {/* </div> */}
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IUsers>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='table-customized' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IUsers>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* <UsersListPagination /> */}
        <PaginationComponent
          values={{...values, role: REACT_APP_ROLE_DRIVER}}
          setValues={setValues}
          endpoint={endpoints.userEndpoint}
          actionType={actionTypes.GET_ALL_USERS}
          totalPages={totalPages}
        />
        {isLoading && <DriversListLoading />}
      </KTCardBody>
    </KTCard>
  )
}

export {DriversTable}
