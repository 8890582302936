// @ts-nocheck
import {Column} from 'react-table'
import {
  UserInfoCell,
  UserTwoStepsCell,
  UserCustomHeader,
  UserActionsCell,
  DriverSelectionHeader,
  DriverSelectionCell,
} from '../../../../../../_template/layout/components/columns'
// import {UserEmailCell} from '../../../../../../components/layout/components/columns/UserEmailCell'
// import {User} from '../../core/_models'
import {FormattedMessage} from 'react-intl'
import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'
import TransportationType from '../../../../../../_template/layout/components/columns/TransportationType'
import {useListView} from '../../core/ListViewProvider'
import {Link} from 'react-router-dom'
import { DriversInfoCell } from '../../../../../../_template/layout/components/columns/driversInfoCell'

// "ECOMMERCE.DRIVERS.FULLNAME": "Driver's Fullname",
// "ECOMMERCE.DRIVERS.TRANSPORTATIONMETHOD": "Driver's Fullname",
// "ECOMMERCE.DRIVERS.ORDER": "Order",
// "ECOMMERCE.DRIVERS.CREATEDBY": "Created by",

const driversColumns: ReadonlyArray<Column<IUsers>> = [
  {
    Header: (props) => <DriverSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <DriverSelectionCell id={props.data[props.row.index]._id} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.DRIVERS.FULLNAME' />}
        className='min-w-125px'
      />
    ),
    id: 'firstName',
    Cell: ({...props}) => (
      <Link to={`/drivers/${props.data[props.row.index]._id}`}>
        <DriversInfoCell user={props.data[props.row.index]} />{' '}
      </Link>
    ),
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title={<FormattedMessage id='ECOMMERCE.COMMON.LAST_NAME'/>} className='min-w-125px' />,
  //   id: 'lastName',
  //   Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title={<FormattedMessage id='ECOMMERCE.COMMON.EMAIL'/>} className='min-w-125px' />,
  //   accessor: 'email',
  // },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='ECOMMERCE.COMMON.PHONENUMBER' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   accessor: 'phoneNumber',
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.DRIVERS.TRANSPORTATIONTYPE' />}
        className='min-w-150px'
      />
    ),
    id: 'deliveryType',
    Cell: ({...props}) => <TransportationType driver={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title={<FormattedMessage id='ECOMMERCE.COMMON.ADDRESS'/>} className='min-w-125px' />
  //   ),
  //   accessor: 'address',
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title={<FormattedMessage id='ECOMMERCE.COMMON.PHONENUMBER'/>} className='min-w-125px' />
  //   ),
  //   accessor: 'phoneNumber',
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CREATIONDATE' />}
        className='min-w-125px'
      />
    ),
    id: 'createDate',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].createDate} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.DRIVERS.CREATEDBY' />}
        className='min-w-125px'
      />
    ),
    id: 'createdBy',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index].createdBy} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CITY' />}
        className='min-w-125px'
      />
    ),
    id: 'address.city',
    Cell: ({...props}) => {
      const address = props.data[props.row.index].address
      if (address && typeof address === 'object') {
        return <div>{props.data[props.row.index].address.city}</div>
      }
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.STATUS' />}
        className='min-w-125px'
      />
    ),
    id: 'status',
    Cell: ({...props}) => (
      <UserTwoStepsCell
        type='driver'
        id={props.data[props.row.index]._id}
        two_steps={props.data[props.row.index].status}
      />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        userType='drivers'
        id={props.data[props.row.index]._id}
        useListView={useListView}
      />
    ),
  },
]

export {driversColumns}
