// import {useQuery} from 'react-query'
import {AssignDriverModalForm} from './AssignDriverModalForm'
// import {isNotEmpty, QUERIES} from '../../../../../components/helpers'
import {useListView} from '../core/ListViewProvider'
// import {getUserById} from '../core/_requests'
import {useAppDispatch} from '../../../../hooks'
// import { getAllFunctionById } from '../../../../redux/actions/commonCrud'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import {useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {getAllFunction, getAllFunctionById} from '../../../../redux/actions/commonCrud'
// import {fetchStart, fetchSuccess} from '../../../../redux/actions/common'
const AssignDriverModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  // const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const order: IOrder = useSelector(({orders}: {orders: OrdersState}) => {
    return orders.oneOrder
  })
  const data = useMemo(() => order, [order])
  const dispatch = useAppDispatch()
  const REACT_APP_ROLE_DRIVER = process.env.REACT_APP_ROLE_DRIVER ||""

  useEffect(() => {
    if (itemIdForUpdate) {
      dispatch(
        getAllFunctionById(endpoints.orderEndpoint, actionTypes.GET_ONE_ORDER, itemIdForUpdate)
      )
      dispatch(
        getAllFunction(endpoints.userEndpoint, actionTypes.GET_ALL_USERS, {
          role: REACT_APP_ROLE_DRIVER 
        //  size: 100,
        })
      )
    }
  }, [dispatch, itemIdForUpdate])

  if (!itemIdForUpdate) {
    return <AssignDriverModalForm isUserLoading={false} order={{_id: undefined}} />
  }

  if (order) {
    return <AssignDriverModalForm isUserLoading={false} order={data} />
  }

  return null
}

export default AssignDriverModalFormWrapper
