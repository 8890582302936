import {ListViewProvider, useListView} from './core/ListViewProvider'
import {DriversListHeader} from './components/header/DriversListHeader'
import {DriversTable} from './table/DriversTable'
import {DriverEditModal} from './driver-edit-modal/DriverEditModal'
import InfoView from '../../../../_template/layout/components/infoView/index'
import * as endpoints from '../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../redux/actionTypes'

import {Tab, Tabs} from 'react-bootstrap'
import {PageTitle} from '../../../../_template/layout/core'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {useAppDispatch} from '../../../hooks'
import {getAllFunction} from '../../../redux/actions/commonCrud'
import getBreadCrambs from '../../../routing/breadcramps/getBreadCrambs'
// import {useSelector} from 'react-redux'
import DocsCount from '../../../components/DocsCount'
const REACT_APP_ROLE_DRIVER = process.env.REACT_APP_ROLE_DRIVER

interface state {
  status?: string | undefined
  role?: string | undefined
  page?: number | undefined
}
const DriversList = () => {
  const {itemIdForUpdate} = useListView()
  const [status, setStatus] = useState<state>({status: undefined, page: 0})
  const intl = useIntl()
  const [count, setCount] = useState(0)

  // const roles: Role | undefined = useSelector(({users}: {users: UsersState}) => {
  //   return users.roles.find((item) => item.name === 'ROLE_DRIVER')
  // })
  const handleSelect = (key: string | null) => {
    if (key === 'INACTIVE') setStatus({...status, status: 'INACTIVE', page: 0})
    else if (key === 'ACTIVE') setStatus({...status, status: 'ACTIVE', page: 0})
    else setStatus({...status, status: undefined})
  }
  const dispatch = useAppDispatch()
  console.log(process.env.REACT_APP_ROLE_DRIVER, 'REACT_APP_ROLE_DRIVERREACT_APP_ROLE_DRIVERREACT_APP_ROLE_DRIVERREACT_APP_ROLE_DRIVER')
  useEffect(() => {
    // dispatch(getAllFunction(endpoints.userRole, actionTypes.GET_ALL_USER_ROLES, {}))
    dispatch(
      getAllFunction(endpoints.userEndpoint, actionTypes.GET_ALL_USERS, {
        ...status,
        role: REACT_APP_ROLE_DRIVER,
      })
    )
  }, [dispatch, status])

  return (
    <>
      <InfoView />
      <div
        className='d-flex justify-content-between'
        style={{marginTop: '-4%', marginBottom: '25px'}}
      >
        <div>
          <PageTitle breadcrumbs={getBreadCrambs(intl)}>
            {intl.formatMessage({id: 'MENU.DRIVERS'})}
          </PageTitle>
        </div>
        <div>
          <DriversListHeader />
        </div>
      </div>
      <Tabs id='controlled-tab-example' defaultActiveKey='All' onSelect={(k) => handleSelect(k)}>
        <Tab
          eventKey='All'
          title={
            <DocsCount
              showNumbers={true}
              count={count}
              title={intl.formatMessage({id: 'ECOMMERCE.COMMON.ALL'})}
            />
          }
        >
          <DriversTable setCount={setCount} eventKey='All' />
        </Tab>
      </Tabs>

      {itemIdForUpdate !== undefined && <DriverEditModal />}
    </>
  )
}

const DriversListWrapper = () => (
  <ListViewProvider>
    <DriversList />
  </ListViewProvider>
)

export {DriversListWrapper}
