/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, FC} from 'react'
import {initialQueryState, KTSVG, useDebounce} from '../../../../../../_template/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {MenuComponent} from '../../../../../../_template/assets/ts/components'
import {useLang} from '../../../../../../_template/i18n/Metronici18n'
import {useIntl} from 'react-intl'
import {useAppDispatch} from '../../../../../hooks'
import * as endpoints from '../../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../../redux/actionTypes'
import {getAllFunction} from '../../../../../redux/actions/commonCrud'
const REACT_APP_ROLE_DRIVER = process.env.REACT_APP_ROLE_DRIVER || ''

type values = {
  role?: string | undefined
  phoneNumber?: string | undefined
  status?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string | undefined
}
type Props = {
  eventKey: string | undefined
  handleDispatch: () => any
  values: values | undefined
  handleChangeValues: (e: any) => any
  resetData: (e: any) => any
}

const DriversListSearchComponent: FC<Props> = ({
  values,
  eventKey,
  handleChangeValues,
  handleDispatch,
  resetData,
}) => {
  const locale: string = useLang()
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm)
  const intl = useIntl()
  const dispatch = useAppDispatch()

  // const dispatch = useAppDispatch()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        dispatch(
          getAllFunction(endpoints.userEndpoint, actionTypes.GET_ALL_USERS, {
            ...values,
            searchUser: searchTerm,
            role: REACT_APP_ROLE_DRIVER,
          })
        )
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  type Status = {name: string; _id: number}
  const status: Status[] = [
    {name: 'Availiable', _id: 1100},
    {name: 'Disabled', _id: 1000},
  ]
  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex'>
        <div
          style={{
            position: 'relative',
          }}
          className='d-flex align-items-center position-relative my-1 w-100'
        >
          {' '}
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute mx-5'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control px-14'
            placeholder={intl.formatMessage({
              id: 'ECOMMERCE.DRIVERS.INPUT_SEARCH_PLACEHOLDER',
            })}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            data-kt-menu-trigger='click'
            data-kt-menu-placement={locale === 'ar' ? 'bottom-start' : 'bottom-end'}
            type='button'
            style={{
              position: 'absolute',
              right: locale !== 'ar' ? 0 : undefined,
              left: locale === 'ar' ? 0 : undefined,
              border: 0,
              zIndex: 5,
            }}
            className='btn btn-icon'
          >
            <KTSVG
              path='/media/icons/duotune/print/Filter.svg'
              className='svg-icon-8 position-absolute'
            />{' '}
          </button>
          {/* begin::SubMenu */}
          <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
            {/* begin::Header */}
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>
                {intl.formatMessage({id: 'ECOMMERCE.COMMON.FILTER_OPTIONS'})}
              </div>
            </div>
            {/* end::Header */}

            {/* begin::Separator */}
            <div className='separator border-gray-200'></div>
            {/* end::Separator */}

            {/* begin::Content */}
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              {/* begin::Input group */}

              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'ECOMMERCE.COMMON.FIRST_NAME'})}
                </label>
                <input
                  type='text'
                  className='form-control rounded form-control-sm form-control-solid  ps-10'
                  name='firstName'
                  value={values?.firstName}
                  onChange={handleChangeValues}
                  placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.FIRST_NAME'})}
                />
              </div>
              {/* end::Input group */}
              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'ECOMMERCE.COMMON.LAST_NAME'})}
                </label>
                <input
                  type='text'
                  className='form-control rounded form-control-sm form-control-solid  ps-10'
                  name='lastName'
                  value={values?.lastName}
                  onChange={handleChangeValues}
                  placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.LAST_NAME'})}
                />
              </div>
              {/* end::Input group */}
              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'ECOMMERCE.COMMON.STATUS'})}
                </label>
                <select
                  className='form-select rounded form-select-solid fw-bolder'
                  data-kt-select2='true'
                  placeholder= {intl.formatMessage({id: 'ECOMMERCE.COMMON.STATUS'})}
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  name='availiable'
                  data-kt-user-table-filter='availiable'
                  data-hide-search='true'
                  onChange={handleChangeValues}
                  value={undefined}
                >
                  {/* <option value=''></option> */}
                  <option value=''>All</option>
                  {status.map((role) => (
                    <option value={role._id as any}>{role.name}</option>
                  ))}
                </select>
              </div>
              {/* end::Input group */}
              {/* begin::Input group */}
              <div className='mb-4'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'ECOMMERCE.COMMON.PHONENUMBER'})}
                </label>
                <input
                  type='text'
                  className='form-control rounded form-control-sm form-control-solid  ps-10'
                  name='phoneNumber'
                  value={values?.phoneNumber}
                  onChange={handleChangeValues}
                  placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.PHONENUMBER'})}
                />
              </div>
              {/* end::Input group */}
              {/* begin::Actions */}
              <div className='d-flex justify-content-between'>
                <button
                  type='button'
                  //disabled={isLoading}
                  onClick={resetData}
                  className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                  data-kt-menu-dismiss='false'
                  data-kt-user-table-filter='reset'
                >
                  {intl.formatMessage({id: 'ECOMMERCE.COMMON.RESET'})}
                </button>
                <button
                  // disabled={isLoading}
                  type='button'
                  onClick={() => handleDispatch()}
                  className='btn btn-design fw-bold px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                >
                  {intl.formatMessage({id: 'ECOMMERCE.COMMON.APPLY'})}
                </button>
              </div>
              {/* end::Actions */}
            </div>
            {/* end::Content */}
          </div>
          {/* end::SubMenu */}
        </div>
      </div>

      {/* end::Search */}
    </div>
  )
}

export {DriversListSearchComponent}
