import React, {FC, useState} from 'react'
import {Formik, Form} from 'formik'
import InfoForm from './info'
import AdressForm from './adress'
import RoleForm from './role'
import SubmissionForm from './submission'
import {isNotEmpty, KTSVG} from '../../../../helpers'
import {initialUser} from '../../../../../app/pages/ListUsers/users-list/core/_models'
import {useQueryResponse} from '../../../../../app/pages/ListUsers/users-list/core/QueryResponseProvider'
import {useAppDispatch} from '../../../../../app/hooks'
import * as endpoints from '../../../../helpers/endpoints'
import * as Yup from 'yup'
import {
  validationSchemaStep1User,
  validationSchemaStep1Driver,
  validationSchemaStep2,
  validationSchemaStep3User,
  validationSchemaStep3Driver,
  validationSchemaStep1UpdateDriver,
  validationSchemaStep1UpdateUser,
} from './validationShema'
import {
  formDataAddFunction,
  formDatapatchFunction,
  getAllFunction,
} from '../../../../../app/redux/actions/commonCrud'
import {useIntl} from 'react-intl'
import * as actionTypes from '../../../../../app/redux/actionTypes'
import Loading from '../../loading/Loading'

type Props = {
  isUserLoading: boolean
  user: IUsers
  type: string
  useListView: any
}

const MultiStepForm: FC<Props> = ({user, type, isUserLoading, useListView}) => {
  const {setItemIdForUpdate} = useListView()
  const blankImg =
    type === 'driver' ? '/media/avatars/driver.svg' : '/media/svg/avatars/avatar_user.svg'
  const dispatch = useAppDispatch()
  const {refetch} = useQueryResponse()
  const [userData] = useState({
    ...user,
    photo: user.photo ? process.env.REACT_APP_API_URL + user.photo.slice(1) : blankImg,
  })

  const intl = useIntl()
  const [step, setStep] = useState(1)
  const steps =
    type === 'driver'
      ? [
          {key: 'PROFILE', label: intl.formatMessage({id: 'ECOMMERCE.USERS.PROFILE_DETAILS'})},
          {key: 'ADDRESS', label: intl.formatMessage({id: 'ECOMMERCE.COMMON.ADDRESS'})},
          {key: 'USER_ROLE', label: intl.formatMessage({id: 'ECOMMERCE.USERS.USER_STATUS'})},
          {key: 'SUBMISSION', label: intl.formatMessage({id: 'ECOMMERCE.USERS.SUBMISSION'})},
        ]
      : [
          {key: 'PROFILE', label: intl.formatMessage({id: 'ECOMMERCE.USERS.PROFILE_DETAILS'})},
          {key: 'ADDRESS', label: intl.formatMessage({id: 'ECOMMERCE.COMMON.ADDRESS'})},
          {key: 'USER_ROLE', label: intl.formatMessage({id: 'ECOMMERCE.USERS.USER_ROLE'})},
          {key: 'SUBMISSION', label: intl.formatMessage({id: 'ECOMMERCE.USERS.SUBMISSION'})},
        ]
  const [userForEdit] = useState<any>({
    ...userData,
    email: userData.email || initialUser.email,
    firstName: userData.firstName || initialUser.firstName,
    lastName: userData.lastName || initialUser.lastName,
    phoneNumber: userData.phoneNumber || initialUser.phoneNumber,
    status: userData.status || initialUser.status,
    role: userData.role || initialUser.role,
    // positionX: userData.positionX || initialUser.positionX,
    deliveryType: userData?.deliveryType?._id || initialUser.deliveryType,
    address: userData.address || initialUser.address,
  })
  const checkStep1 = (values: any) =>
    type === 'user'
      ? values && values?.email && values?.firstName && values?.lastName && values?.phoneNumber
      : values?.email &&
        values?.firstName &&
        values?.lastName &&
        values?.phoneNumber &&
        values?.deliveryType

  const checkStep2 = (values: any) =>
    values &&
    values?.address &&
    values?.address?.addressLine1 &&
    values?.address?.city &&
    values?.address?.zipCode &&
    values?.address?.state

  const checkStep3 = (values: any) =>
    type === 'user' ? values && values?.role && values?.status : values && values?.status

  const handleNext = (values: IUsers) => {
    if (step === 1 && checkStep1(values)) {
      setStep((prevStep) => prevStep + 1)
      return
    }

    if (step === 2 && checkStep1(values) && checkStep2(values)) {
      setStep((prevStep) => prevStep + 1)
      return
    }

    if (step === 3 && checkStep1(values) && checkStep2(values) && checkStep3(values)) {
      setStep((prevStep) => prevStep + 1)
      return
    }
  }

  const handlePrevious = () => {
    setStep((prev) => prev - 1)
  }

  const getCurrentValidationSchema = () => {
    switch (step) {
      case 1:
        return type === 'driver' && user._id
          ? validationSchemaStep1UpdateDriver(intl)
          : type === 'driver' && !user._id
          ? validationSchemaStep1Driver(intl)
          : type === 'user' && user._id
          ? validationSchemaStep1UpdateUser(intl)
          : validationSchemaStep1User(intl)
      case 2:
        return validationSchemaStep2(intl)
      case 3:
        return type === 'driver'
          ? validationSchemaStep3Driver(intl)
          : validationSchemaStep3User(intl)
      case 4:
        return Yup.object().shape({})
      default:
        return Yup.object().shape({})
    }
  }
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    dispatch({type: actionTypes.GET_ALL_USERS_BY_ID, payload: {}})
    setItemIdForUpdate(undefined)
  }
  const userStatus = localStorage.getItem('status')
  const onSubmit = async (values: any) => {
    try {
      const formData = new FormData()
      let status = null
      if (values.driverArea && values.driverArea.length) {
        values = {
          ...values,
          driverArea: values.driverArea
            .filter((item: any) => item.value !== undefined)
            ?.map((value: any) => value.value),
        }
      }
      if (isNotEmpty(values._id)) {
        // this is to sendonly the changed values in the stepper
        const filteredValues = Object.keys(values).reduce((acc: any, key) => {
          if (values[key] !== userForEdit[key]) {
            acc[key] = values[key]
          }
          return acc
        }, {})

        for (const attr in filteredValues) {
          if (attr === 'address') {
            formData.append('address[addressLine1]', values.address.addressLine1)
            formData.append('address[addressLine2]', values.address.addressLine2)
            formData.append('address[city]', values.address.city)
            formData.append('address[state]', values.address.state)
            formData.append('address[country]', values.address.country)
            formData.append('address[zipCode]', values.address.zipCode)
            formData.append('address[positionX]', values.address.positionX)
            formData.append('address[positionY]', values.address.positionY)
          } else if (attr === 'driverArea') {
            let array = values.driverArea
            if (array.length > 0) {
              array.map((item: string, index: any) => formData.append(`driverArea[${index}]`, item))
            }
          } else formData.append(attr, values[attr])
        }

        status = await dispatch(formDatapatchFunction(endpoints.userEndpoint, formData, values._id))
      } else {
        // if it is an add function (id is not defined)
        for (const attr in values) {
          if (attr === 'address') {
            formData.append('address[addressLine1]', values.address.addressLine1)
            formData.append('address[addressLine2]', values.address.addressLine2)
            formData.append('address[city]', values.address.city)
            formData.append('address[state]', values.address.state)
            formData.append('address[country]', values.address.country)
            formData.append('address[zipCode]', values.address.zipCode)
            formData.append('address[positionX]', values.address.positionX)
            formData.append('address[positionY]', values.address.positionY)
          } else if (attr === 'driverArea') {
            let array = values.driverArea
            if (array.length > 0) {
              array.map((item: string, index: any) => formData.append(`driverArea[${index}]`, item))
            }
          } else if (attr !== 'role') {
            formData.append(attr, values[attr])
          }
        }
        formData.delete('_id')

        if (type === 'driver') {
          status = await dispatch(formDataAddFunction(endpoints.driverEndpoint, formData))
        } else {
          formData.delete('deliveryType')
          formData.append('roleId', values.role)
          status = await dispatch(formDataAddFunction(endpoints.userEndpoint, formData))
        }
      }
      const REACT_APP_ROLE_DRIVER = process.env.REACT_APP_ROLE_DRIVER ||""

      // to get all data after update
      if (type === 'driver') {
        if (status.type === 'FETCH_SUCCESS') {
          setTimeout(() => {
            dispatch(
              getAllFunction(endpoints.userEndpoint, actionTypes.GET_ALL_USERS, {
                role: REACT_APP_ROLE_DRIVER,
              })
            )
          }, 500)
        }
      } else {
        if (status.type === 'FETCH_SUCCESS') {
          setTimeout(() => {
            dispatch(
              getAllFunction(endpoints.userEndpoint, actionTypes.GET_ALL_USERS, {
                status: userStatus,
              })
            )
          }, 500)
        }
      }
      cancel(true)
    } catch (ex) {
      cancel(true)
    } finally {
      cancel(true)
    }
  }

  return (
    <>
      {isUserLoading && <Loading />}
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* <InfoView /> */}
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px '>
          {/* begin::Modal content */}
          <div className='modal-content' style={{borderRadius: '24px'}}>
            <div className='modal-header-customized '>
              {/* begin::Modal title */}
              <div className='d-flex  flex-sm-row flex-column justify-content-between w-100'>
                {steps.map(({label, key}, index) =>
                  index === step - 1 ? (
                    <span
                      key={key}
                      style={{
                        color: '#E9A800',
                        borderBottom: 'solid',
                        paddingBottom: '18px',
                        fontSize: '18px',
                      }}
                    >
                      {label}
                    </span>
                  ) : (
                    <span className='fs-16px' key={key}>
                      {label}
                    </span>
                  )
                )}
              </div>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => {
                  if (user) {
                    dispatch({type: actionTypes.GET_ALL_USERS_BY_ID, payload: {}})
                    setItemIdForUpdate(undefined)
                  } else setItemIdForUpdate(undefined)
                }}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-2 mx-xl-5 my-7'>
              <Formik
                enableReinitialize={true}
                initialValues={userForEdit}
                validationSchema={getCurrentValidationSchema}
                onSubmit={(values) => onSubmit(values)}
                // validateOnChange={true}
                validateOnMount
                validateOnBlur
              >
                {({isSubmitting, values, isValid, touched, dirty}) => (
                  <Form>
                    <div
                      className='d-flex flex-column me-n7 pe-7'
                      id='kt_modal_add_user_scroll'
                      data-kt-scroll='true'
                      data-kt-scroll-activate='{default: false, lg: true}'
                      data-kt-scroll-max-height='auto'
                      data-kt-scroll-dependencies='#kt_modal_add_user_header'
                      data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                      data-kt-scroll-offset='300px'
                    >
                      {step === 1 && <InfoForm type={type} />}
                      {step === 2 && <AdressForm />}
                      {step === 3 && <RoleForm type={type} />}
                      {step === 4 && <SubmissionForm type={type} />}
                    </div>
                    {/* begin::Actions */}
                    <div className='d-flex justify-content-end pt-15'>
                      <button
                        type={'button'}
                        onClick={() => (step === 1 ? cancel() : handlePrevious())}
                        className='btn btn-lightBtn me-3'
                        disabled={isUserLoading}
                      >
                        {step === 1
                          ? intl.formatMessage({id: 'ECOMMERCE.COMMON.CANCEL'})
                          : intl.formatMessage({id: 'ECOMMERCE.COMMON.BACK'})}
                      </button>

                      <button
                        type={'button'}
                        onClick={() => (step === 4 ? onSubmit(values) : handleNext({...values}))}
                        className='btn btn-design'
                        disabled={isUserLoading || isSubmitting || !isValid || !touched}
                      >
                        <span className='indicator-label'>
                          {step === 4
                            ? intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})
                            : intl.formatMessage({id: 'ECOMMERCE.USERS.NEXT_STEP'})}
                        </span>
                        {(isSubmitting || isUserLoading) && (
                          <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {/* end::Actions */}
                  </Form>
                )}
              </Formik>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default MultiStepForm
