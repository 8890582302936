import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'

import {useListView} from '../core/ListViewProvider'
// import CustomersListLoading from '../components/loading/deliveryTypeLoading'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {IntlShape} from '@formatjs/intl'
import * as endpoints from '../../../../../_template/helpers/endpoints'
import * as actionTypes from '../../../../redux/actionTypes'
import Loading from '../../../../../_template/layout/components/loading/Loading'
import {CustomSelect} from '../../../../../_template/layout/components/customSelect/customSelect'
import {useAppDispatch} from '../../../../hooks'
import {OrdersListLoading} from '../components/loading/OrdersListLoading'
import {initialOrder} from '../core/_models'
import {useSelector} from 'react-redux'
import {getAllFunction, patchFunction} from '../../../../redux/actions/commonCrud'

type Props = {
  isUserLoading: boolean
  order: IOrder
}

const DriverSchema = (intl: IntlShape<any>) =>
  Yup.object().shape({
    driver: Yup.string()
      // .max(50, intl.formatMessage({id: 'AUTH.GENERAL.MAX30'}))
      .required(intl.formatMessage({id: 'AUTH.GENERAL.NAME_IS_REQUIRED'})),
  })

const AssignDriverModalForm: FC<Props> = ({order, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [itemForEdit, setItemForEdit] = useState<IUsers>({
    driver: initialOrder.driver?._id,
  })
  useEffect(() => {
    if (order)
      setItemForEdit({
        driver: order.driver?._id,
      })
  }, [order])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  // const category = [
  //   {name: intl.formatMessage({id: 'ECOMMERCE.COMMON.MOTO'}), _id: 10},
  //   {name: intl.formatMessage({id: 'ECOMMERCE.COMMON.CAR'}), _id: 100},
  //   {name: intl.formatMessage({id: 'ECOMMERCE.COMMON.HEAVY_WEIGHTS'}), _id: 200},
  // ]
  const users: IUsers[] = useSelector(({users}: {users: UsersState}) => {
    return users.usersList
  })
  console.log(order.suggestionID,"order")
  return (
    <>
      {isUserLoading && <Loading />}
      <Formik
        enableReinitialize={true}
        initialValues={itemForEdit}
        validationSchema={DriverSchema(intl)}
        onSubmit={async (values, {setSubmitting}) => {
          setSubmitting(true)

          if (values.driver) {
            try {
              await dispatch(
                patchFunction(
                  endpoints.assignDriver,
                  {driverId: values.driver, sueggestionID: order.suggestionID},
                  order._id
                )
              )
            } catch (ex) {
            } finally {
              setSubmitting(true)
              cancel(true)
              setTimeout(() => {
                dispatch(
                  getAllFunction(endpoints.deliveryType, actionTypes.GET_ALL_DELIVERY_TYPES, {})
                )
              }, 500)
            }
          }
        }}
        validateOnChange={false}
      >
        {({isSubmitting, values, isValid, touched, getFieldProps, errors}) => (
          <Form>
            <div
              className='d-flex flex-column scroll-y me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              <div>
                {/* begin::Input group */}

                {/* end::Input group */}
                <div className='fv-row mb-7'>
                  {/* begin::Input */}

                  <div className='mb-10'>
                    <CustomSelect
                      className='form-select rounded form-select-solid fw-bolder'
                      data-kt-select2='true'
                      placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.DRIVER'})}
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-hide-search='true'
                      name='driver'
                      label={intl.formatMessage({id: 'ECOMMERCE.COMMON.DRIVER'})}
                      options={users}
                    />
                  </div>
                  {/* end::Input */}
                </div>

                {/* end::Input group */}
              </div>
            </div>
            {/* end::Scroll */}

            {/* begin::Actions */}
            <div className='d-flex justify-content-between pt-15'>
              <button
                type='reset'
                onClick={() => cancel()}
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                disabled={isSubmitting || isUserLoading}
              >
                {intl.formatMessage({ id: 'ECOMMERCE.COMMON.DISCARD' })}
              </button>

              <button
                type='submit'
                className='btn btn-design'
                data-kt-users-modal-action='submit'
                disabled={isUserLoading || isSubmitting || !isValid || !touched}
              >
                <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}</span>
                {(isSubmitting || isUserLoading) && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
            {(isSubmitting || isUserLoading) && <OrdersListLoading />}
          </Form>
        )}
      </Formik>
    </>
  )
}

export {AssignDriverModalForm}
